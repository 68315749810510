body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-slide {
	padding: 0 4px;
  box-sizing: border-box;
  margin: 4px 0;
}

.MuiExpansionPanelSummary-root.Mui-focused {
  background-color: transparent !important;
}

.MuiTableCell-paddingNone {
  padding: 4px 8px !important;
}